.ja-button__wrapper {
  position: relative;
  display: block;
}

.ja-button__button {
  position: relative;
  display: block;
  width: 20rem;
  max-width: 50rem;
  min-height: 45px;
  padding: 0.8rem 1.5rem;
  margin: 1rem 0;
  border-radius: 1.2rem;
  text-align: center;
  font-size: 1.8rem;
  background: var(--vivid-purple);
  color: var(--light);
  cursor: pointer;
  transition: var(--transition), opacity 0.5s ease;
  z-index: 999;
}

.ja-button__button:not(:disabled):hover {
  background: var(--vivid-purple__hover);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  transform: translateY(-1px);
}

.ja-button__button:disabled {
  pointer-events: none;
  background: var(--purple);
  color: var(--secondary);
  color: var(--light);
}

.ja-button__button:active {
  transform: translateY(1px);
}
