@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,300;0,400;0,600;1,300&family=Playfair+Display:wght@700&display=swap);
.jewelry-modal__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background: var(--shade2);
}

.jewelry-modal__content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%);
  width: 90%;
  max-width: 1000px;
  height: 90%;
  max-height: 650px;
  border: 2px solid var(--accent);
  border-radius: 1.2rem;
  background-color: var(--light);
  overflow: auto;
  outline: 0;
}

.jewelry-modal {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-basis: 100%;
}

.jewelry-modal__header {
  position: relative;
  top: 0;
  height: 75px;
  min-height: 75px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: var(--shade2_100);
}

.jewelry-modal__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  margin-right: 1rem;
}

.jewelry-modal__title {
  font-size: 2.5rem;
}

.jewelry-modal__icon img {
  height: 5rem;
}

.jewelry-modal__body {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 3rem 2rem;
  overflow-y: auto;
}

.jewelry-modal__language {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  padding-right: 1.5rem;
}

.jewelry-modal__language--first-of {
  border-right: 1px solid var(--accent);
}

.jewelry-modal__language:not(.jewelry-modal__language--first-of) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.jewelry-modal__language:not(.jewelry-modal__language--first-of)
  .jewelry-modal__language-content-container {
  width: 70%;
}

.jewelry-modal__language
  .jewelry-modal__language-content-container:not(:first-of-type) {
  margin-top: 3rem;
}

.jewelry-modal__language h3 {
  margin-bottom: 1.5rem;
}

.jewelry-modal__language h3:not(:first-of-type) {
  margin-top: 3rem;
}

.jewelry-modal__language-title {
  font-size: 2.2rem;
}

.jewelry-modal ul {
  list-style: none;
  list-style-image: url(/static/media/bullet.256d7000.svg);
  padding-left: 2rem;
}

.jewelry-modal__language-description-list li {
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: var(--secondary);
}

.jewelry-modal__report {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-left: 1.5rem;
}

.jewelry-modal__report-content {
  width: 100%;
  flex-basis: 0;
  flex-grow: 8;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.jewelry-modal__report-content h3 {
  margin-bottom: 1.5rem;
}

.jewelry-modal__report-content h3 {
  font-size: 2.2rem;
}

.jewelry-modal__report-content li {
  font-size: 1.8rem;
  line-height: 3rem;
  color: var(--secondary);
}

.jewelry-modal .jewelry-modal__tel-call {
  color: var(--link);
}

.jewelry-modal__tel-call:hover {
  color: var(--link-change);
}

a.jewelry-modal__tel-call:active::after,
a.jewelry-modal__tel-call:focus::after {
  border: 0;
  outline: 0;
}

.jewelry-modal__footer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  height: 60px;
  min-height: 60px;
  background: var(--shade2_100);
}

.jewelry-modal__cta--info,
.jewelry-modal__cta {
  display: inline-block;
  width: 100%;
  max-width: 320px;
  height: 100%;
  max-height: 40px;
  margin: 10px;
  border: 2px solid var(--vivid-purple);
  border-radius: 1.2rem;
  font-size: 1.6rem;
  color: var(--light);
  background: var(--vivid-purple);
  letter-spacing: 0.1rem;
}

.jewelry-modal__cta--info {
  max-width: 275px;
  color: var(--link);
  background: transparent;
  border-color: var(--link);
  opacity: 0.75;
}

.jewelry-modal__cta:hover {
  color: var(--light);
  background: var(--link-change);
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}

.jewelry-modal__cta--info:hover {
  color: var(--link-change);
  background: transparent;
  border-color: var(--link-change);
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
  opacity: 1;
}

.jewelry-modal__cta:active,
.jewelry-modal__cta--info:active {
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}

@media (max-width: 900px) {
  .jewelry-modal__body {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .jewelry-modal__language,
  .jewelry-modal__report {
    display: block;
    flex-basis: 100%;
    padding-bottom: 2rem;
    border: 0;
    height: unset;
  }

  .jewelry-modal__language--first-of {
    border-bottom: 1px solid var(--accent);
  }

  .jewelry-modal__report {
    padding-top: 3rem;
    border: 0;
  }
  .jewelry-modal__language:not(.jewelry-modal__language--first-of)
    .jewelry-modal__language-content-container {
    width: 90%;
  }
}

@media (max-width: 620px) {
  .jewelry-modal__title {
    max-width: 207px;
  }
  .jewelry-modal__cta {
    max-width: unset;
    align-self: center;
  }
  .jewelry-modal__cta--info {
    display: none;
  }
  .jewelry-modal__content {
    width: 100%;
    height: 100%;
    max-height: unset;
    border-radius: 0;
    border-width: 1px;
  }
}

@media (max-width: 450px) {
  .jewelry-modal__content {
    min-height: 90%;
  }
}

.purpose-modal__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background: var(--shade2);
}

.purpose-modal__content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%);
  width: 90%;
  max-width: 650px;
  height: 90%;
  max-height: 600px;
  border: 2px solid var(--accent);
  border-radius: 1.2rem;
  background-color: var(--light);
  overflow: auto;
  outline: 0;
}

.purpose-modal {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-basis: 100%;
}

.purpose-modal__header {
  position: relative;
  top: 0;
  height: 75px;
  min-height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--shade2_100);
}

.purpose-modal__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  margin-right: 1rem;
}

.purpose-modal__title {
  font-size: 2.5rem;
}

.purpose-modal__icon img {
  height: 5rem;
}

.purpose-modal__body {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 3rem 2rem;
  overflow-y: auto;
}

.purpose-modal__language {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.purpose-modal__language--first-of {
  border-right: 1px solid var(--accent);
}

.purpose-modal__language-title {
  text-align: center;
  font-size: 2.8rem;
}

.purpose-modal ul {
  list-style: none;
  list-style-image: url(/static/media/bullet-2x.f34e307a.svg);
  padding-left: 2rem;
  max-width: 400px;
  margin: 0 auto;
}

.purpose-modal__language-description-list li {
  margin-top: 2rem;
  font-size: 2.4rem;
  line-height: 3rem;
  color: var(--secondary);
}

.purpose-modal__report {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-left: 1.5rem;
}

.purpose-modal__report-content {
  width: 100%;
  flex-basis: 0;
  flex-grow: 8;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.purpose-modal__report-content h3 {
  margin-bottom: 1.5rem;
}

.purpose-modal__report-content h3 {
  font-size: 2.2rem;
}

.purpose-modal__report-content li {
  font-size: 1.8rem;
  line-height: 3rem;
  color: var(--secondary);
}

.purpose-modal .purpose-modal__tel-call {
  color: var(--link);
}

.purpose-modal__tel-call:hover {
  color: var(--link-change);
  text-decoration: underline;
}

.purpose-modal__footer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  height: 60px;
  min-height: 60px;
  background: var(--shade2_100);
}

.purpose-modal__cta--info,
.purpose-modal__cta {
  display: inline-block;
  width: 100%;
  max-width: 330px;
  height: 100%;
  max-height: 40px;
  margin: 10px;
  border: 2px solid var(--vivid-purple);
  border-radius: 1.2rem;
  font-size: 1.6rem;
  color: var(--light);
  background: var(--vivid-purple);
  letter-spacing: 0.1rem;
}

.purpose-modal__cta--info {
  max-width: 275px;
  color: var(--link);
  background: transparent;
  border-color: var(--link);
  opacity: 0.75;
}

.purpose-modal__cta:hover {
  color: var(--light);
  background: var(--link-change);
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}

.purpose-modal__cta--info:hover {
  color: var(--link-change);
  background: transparent;
  border-color: var(--link-change);
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
  opacity: 1;
}

.purpose-modal__cta:active,
.purpose-modal__cta--info:active {
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}

@media (max-width: 900px) {
  .purpose-modal__body {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .purpose-modal__language,
  .purpose-modal__report {
    display: block;
    flex-basis: 100%;
    padding-bottom: 2rem;
    border: 0;
    height: unset;
  }

  .purpose-modal__language--first-of {
    border-bottom: 1px solid var(--accent);
  }

  .purpose-modal__report {
    padding-top: 3rem;
    border: 0;
  }
}

@media (max-width: 768px) {
}

@media (max-width: 620px) {
  .purpose-modal__title {
    max-width: 207px;
  }

  .purpose-modal__cta {
    max-width: unset;
    align-self: center;
  }

  .purpose-modal__cta--info {
    display: none;
  }

  .purpose-modal__header {
    justify-content: flex-start;
  }

  .purpose-modal__content {
    width: 100%;
    height: 100%;
    max-height: unset;
    border-radius: 0;
    border-width: 1px;
  }
}

@media (max-width: 450px) {
  .purpose-modal__content {
    min-height: 50%;
  }
}

.modal__close-modal,
.modal__close-modal:focus::after {
  border: 0;
  outline: 0;
}

.modal__close-modal,
.modal__close-modal-icon svg {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 4rem;
  height: 4rem;
  fill: var(--secondary);
  outline: 0;
  z-index: 99;
}

.ReactModal__Content button.modal__close-modal:active::after,
.ReactModal__Content button.modal__close-modal:focus::after {
  border: 0;
}

.modal__close-modal:hover svg {
  fill: var(--primary);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.spinner__container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--faint);
}

.spinner__loader,
.spinner__loader:after {
  border-radius: 50%;
  width: 12rem;
  height: 12rem;
}

.spinner__loader {
  position: fixed;
  display: block;
  margin: 0 auto;
  font-size: 1rem;
  text-indent: -9999rem;
  border-top: 1.2rem solid var(--shadeLighter);
  border-right: 1.2rem solid var(--shadeLighter);
  border-bottom: 1.2rem solid var(--shadeLighter);
  border-left: 1.2rem solid var(--vivid-purple);
  top: 50%;
  left: 50%;
  margin-top: -6rem;
  margin-left: -6rem;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-animation: spin 1.1s infinite linear;
          animation: spin 1.1s infinite linear;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner__container--inline {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background: transparent;
}

.spinner__container--inline .spinner__loader {
  position: unset;
  justify-self: flex-start;
  width: 20px;
  height: 20px;
  margin: 0;
  margin-top: 2.5px;
  margin-right: 20px;
  border: 3px solid var(--shade2);
  border-top: 3px solid var(--faint);
  border-radius: 50%;
  transition: all 0.25s linear;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
}
.spinner__container--inline .spinner__loader::after {
  display: none;
}

.get-in-touch-form__form {
  display: flex;
  flex-direction: column;
}

.get-in-touch-form__form-row,
.get-in-touch-form__form-row--button {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.get-in-touch-form__form-row--button {
  justify-content: flex-end;
  align-items: flex-end;
  flex-flow: column nowrap;
}

.get-in-touch-form__form-row.get-in-touch-form__form-row--button
  .ja-button__wrapper {
  flex: 65% 1;
  width: 65%;
  min-width: 65%;
  max-width: 65%;
  transition: all 0.2s ease;
}

.get-in-touch-form__form-row.get-in-touch-form__form-row--button
  .ja-button__button {
  width: unset;
  min-width: 100%;
  max-width: unset;
  border: 2px solid transparent;
}

.get-in-touch-form__form-row--button-success.get-in-touch-form__form-row--button
  button.ja-button__button {
  border-color: var(--success);
  background: var(--success103);
  color: var(--primary);
  text-align: left;
}

.get-in-touch-form__form-row--button-failed.get-in-touch-form__form-row--button
  button.ja-button__button {
  border-radius: 0;
  color: var(--light);
  background: var(--danger);
}

.get-in-touch-form__retry-container {
  flex: 65% 1;
  width: 65%;
  min-width: 65%;
  max-width: 65%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.get-in-touch-form__retry-button {
  padding: 0.8rem 2rem;
  font-size: 1.4rem;
  color: var(--link);
  text-align: center;
  border: 1px solid var(--link);
  border-radius: 1.2rem;
  cursor: pointer;
}

.get-in-touch-form__retry-button:hover {
  background: var(--hover);
  border-color: var(--link-change);
  color: var(--link-change);
}

@-webkit-keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.get-in-touch-form__label {
  display: block;
  flex: 34% 1;
  line-height: 26px;
  padding-top: 8px;
  margin-left: 5px;
  font-size: 1.6rem;
}

.get-in-touch-form__label .note {
  line-height: 17px;
  width: 75%;
}

.get-in-touch-form__label.get-in-touch-form__label--has-focus {
  color: var(--vivid-purple);
}

.get-in-touch-form__input {
  position: relative;
  display: block;
  flex: 65% 1;
  width: auto;
  line-height: 26px;
  padding: 5px 20px 8px 13px;
  border: 1px solid transparent;
  border-radius: 12px;
  background-color: var(--purple-bg);
  color: var(--vivid-purple);
  font-weight: 400;
  font-size: 1.8rem;
  outline: none;
  transition: background-color 0.1s ease-in, color 0.1s ease-in;
}

.get-in-touch-form__input.get-in-touch-form__input--select {
  padding: 0;
  width: 100%;
}

.get-in-touch-form__input:focus {
  background-color: var(--faint);
  border: 1px solid var(--purple-bg);
}

.get-in-touch-form__input--error,
.get-in-touch-form__input--error:focus {
  color: var(--light);
  background: var(--danger);
  border-color: var(--danger) !important;
}

.get-in-touch-form__input--error::-webkit-input-placeholder {
  color: var(--light);
}

.get-in-touch-form__input--error::placeholder {
  color: var(--light);
}

.get-in-touch-form__input--error + #feedback::after {
  content: '!';
  position: absolute;
  width: 29px;
  height: 29px;
  line-height: 29px;
  color: var(--danger);
  font-size: 13px;
  right: 6px;
  top: 6px;
  text-align: center;
  font-weight: bold;
  border-radius: 1.2rem;
  background: var(--light);
  z-index: 999999;
}

.get-in-touch-form__form-row--button .ja-button__button:focus::after {
  border: 0;
}

#feedback {
  display: flex;
  flex-basis: 100%;
  justify-content: flex-start;
  margin-left: 37%;
  margin-top: 5px;
  color: var(--danger);
  letter-spacing: 1px;
  font-size: 12px;
}

@media (max-width: 825px) {
  .get-in-touch-form__form-row,
  .get-in-touch-form__form-row--button {
    width: 100%;
    flex-direction: column;
  }

  .get-in-touch-form__input {
    width: 100%;
  }

  .get-in-touch-form__label {
    width: 100%;
    flex: 100% 1;
    font-size: 16px;
    padding-bottom: 5px;
  }

  .get-in-touch-form__label .note {
    width: 100%;
  }

  .get-in-touch-form__input {
    flex: 100% 1;
  }

  .get-in-touch-form__retry-container,
  .get-in-touch-form__form-row.get-in-touch-form__form-row--button
    .ja-button__wrapper {
    flex: 100% 1;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  .get-in-touch-form__input--error + #feedback::after {
    top: 45px;
  }

  .get-in-touch-form__input--error + #feedback {
    margin-left: unset;
    margin-right: 1.2rem;
    align-self: flex-end;
  }
}

.ja-button__wrapper {
  position: relative;
  display: block;
}

.ja-button__button {
  position: relative;
  display: block;
  width: 20rem;
  max-width: 50rem;
  min-height: 45px;
  padding: 0.8rem 1.5rem;
  margin: 1rem 0;
  border-radius: 1.2rem;
  text-align: center;
  font-size: 1.8rem;
  background: var(--vivid-purple);
  color: var(--light);
  cursor: pointer;
  transition: var(--transition), opacity 0.5s ease;
  z-index: 999;
}

.ja-button__button:not(:disabled):hover {
  background: var(--vivid-purple__hover);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}

.ja-button__button:disabled {
  pointer-events: none;
  background: var(--purple);
  color: var(--secondary);
  color: var(--light);
}

.ja-button__button:active {
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}

.note {
  font-size: 13px;
  flex: 100% 1;
  width: 100%;
  color: var(--purple_60);
}

.free-consultation-form__form {
  display: flex;
  flex-direction: column;
}

.free-consultation-form__form-row,
.free-consultation-form__form-row--button {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.free-consultation-form__form-row--button {
  justify-content: flex-end;
  align-items: flex-end;
  flex-flow: column nowrap;
}

.free-consultation-form__form-row.free-consultation-form__form-row--button
  .ja-button__wrapper {
  flex: 65% 1;
  width: 65%;
  min-width: 65%;
  max-width: 65%;
  transition: all 0.2s ease;
}

.free-consultation-form__form-row.free-consultation-form__form-row--button
  .ja-button__button {
  max-width: unset;
  width: unset;
  min-width: 100%;
  border: 2px solid transparent;
}

.free-consultation-form__form-row--button-success.free-consultation-form__form-row--button
  button.ja-button__button {
  border-color: var(--success);
  background: var(--success103);
  color: var(--primary);
  text-align: left;
}

.free-consultation-form__form-row--button-failed.free-consultation-form__form-row--button
  button.ja-button__button {
  border-radius: 0;
  color: var(--light);
  background: var(--danger);
}

.free-consultation-form__retry-container {
  flex: 65% 1;
  width: 65%;
  min-width: 65%;
  max-width: 65%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.free-consultation-form__retry-button {
  padding: 0.8rem 2rem;
  font-size: 1.4rem;
  color: var(--link);
  text-align: center;
  border: 1px solid var(--link);
  border-radius: 1.2rem;
  cursor: pointer;
}

.free-consultation-form__retry-button:hover {
  background: var(--hover);
  border-color: var(--link-change);
  color: var(--link-change);
}

@-webkit-keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.free-consultation-form__label {
  display: block;
  flex: 34% 1;
  line-height: 26px;
  padding-top: 8px;
  margin-left: 5px;
  font-size: 1.6rem;
}

.free-consultation-form__label .note {
  line-height: 17px;
  width: 75%;
}

.free-consultation-form__label.free-consultation-form__label--has-focus {
  color: var(--vivid-purple);
}

.free-consultation-form__input {
  position: relative;
  display: block;
  flex: 65% 1;
  width: auto;
  line-height: 26px;
  padding: 5px 20px 8px 13px;
  border: 1px solid transparent;
  border-radius: 12px;
  background-color: var(--purple-bg);
  color: var(--vivid-purple);
  font-weight: 400;
  font-size: 1.8rem;
  outline: none;
  transition: background-color 0.1s ease-in, color 0.1s ease-in;
}

.free-consultation-form__input.free-consultation-form__input--select {
  padding: 0;
  width: 100%;
}

.free-consultation-form__input:focus {
  background-color: var(--faint);
  border: 1px solid var(--purple-bg);
}

.free-consultation-form__input--error,
.free-consultation-form__input--error:focus {
  color: var(--light);
  background: var(--danger);
  border-color: var(--danger) !important;
}

.free-consultation-form__input--error::-webkit-input-placeholder {
  color: var(--light);
}

.free-consultation-form__input--error::placeholder {
  color: var(--light);
}

.free-consultation-form__input--error + #feedback::after {
  content: '!';
  position: absolute;
  width: 29px;
  height: 29px;
  line-height: 29px;
  color: var(--danger);
  font-size: 13px;
  right: 6px;
  top: 6px;
  text-align: center;
  font-weight: bold;
  border-radius: 1.2rem;
  background: var(--light);
  z-index: 999999;
}

.free-consultation-form__form-row--button .ja-button__button:focus::after {
  border: 0;
}

#feedback {
  display: flex;
  flex-basis: 100%;
  justify-content: flex-start;
  margin-left: 37%;
  margin-top: 5px;
  color: var(--danger);
  letter-spacing: 1px;
  font-size: 12px;
}

@media (max-width: 825px) {
  .free-consultation-form__form-row,
  .free-consultation-form__form-row--button {
    width: 100%;
    flex-direction: column;
  }

  .free-consultation-form__input {
    width: 100%;
  }

  .free-consultation-form__label {
    width: 100%;
    flex: 100% 1;
    font-size: 16px;
    padding-bottom: 5px;
  }

  .free-consultation-form__label .note {
    width: 100%;
  }

  .free-consultation-form__input {
    flex: 100% 1;
  }

  .free-consultation-form__retry-container,
  .free-consultation-form__form-row.free-consultation-form__form-row--button
    .ja-button__wrapper {
    flex: 100% 1;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  .free-consultation-form__input--error + #feedback::after {
    top: 45px;
  }

  .free-consultation-form__input--error + #feedback {
    margin-left: unset;
    margin-right: 1.2rem;
    align-self: flex-end;
  }
}

.contact-slideout__wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  transition: all 0.3s ease-in-out;
}

.contact-slideout__wrapper.contact-slideout__wrapper--open::before {
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 98;
  background: var(--shade2);
  transition: all 0.5s linear;
}

.contact-slideout__wrapper.contact-slideout__wrapper--closed::before {
  content: '';
  position: fixed;
  background: var(--shade2);
  opacity: 0;
  transition: all 0.5s linear;
}

.contact-slideout__wrapper.contact-slideout__wrapper--open {
  opacity: 1;
  right: 0;
  transition: all 0.5s ease-in-out;
}

.contact-slideout__wrapper.contact-slideout__wrapper--close {
  right: -200vw;
  transition: all 1s ease-in-out;
}

.contact-slideout__container {
  position: relative;
  height: 100%;
  width: 75%;
  max-width: 70rem;
  padding: 2rem 4rem;
  margin-left: auto;
  background: var(--faint);
  overflow: scroll;
  z-index: 99;
  /* transition: all 0.5s ease-in-out; */
}

.contact-slideout__wrapper.contact-slideout__wrapper--open
  .contact-slideout__container {
  right: 0;
  transition: all 0.5s ease-in-out;
}

.contact-slideout__wrapper.contact-slideout__wrapper--close
  .contact-slideout__container {
  right: -200vw;
  transition: all 1s ease-in-out;
}

.contact-slideout__header {
  display: flex;
  height: 50px;
  border-bottom: 1px solid var(--purple-bg);
  margin-bottom: 2rem;
  justify-content: space-between;
  align-items: center;
}

.contact-slideout__close > .inline-svg__parent {
  display: block;
}

.contact-slideout__close-icon,
.contact-slideout__close-icon svg {
  display: block;
  width: 4rem;
  height: 4rem;
  color: var(--secondary);
  fill: var(--secondary);
  transition: all 0.2s ease-in-out;
}

.contact-slideout__close:hover svg {
  fill: var(--primary);
}

.contact-slideout__header button.contact-slideout__close:active::after,
.contact-slideout__header button.contact-slideout__close:focus::after {
  border: 0;
}

.contact-slideout__title {
  position: relative;
  color: var(--purple);
  font-size: 2.4rem;
  margin: 0;
}

@media (max-width: 825px) {
  .contact-slideout__container {
    padding: 2rem;
  }
}

@media (max-width: 625px) {
  .contact-slideout__container {
    width: 100%;
  }
}

@font-face {
  font-family: 'Isabel Bold';
  src: local('Isabel Bold'), local('Isabel-Bold'),
    url(/static/media/Isabel-Bold.0728049d.woff2) format('woff2'),
    url(/static/media/Isabel-Bold.ba5acbdf.woff) format('woff'),
    url(/static/media/Isabel-Bold.648c3e93.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Isabel Light';
  src: local('Isabel Light'), local('Isabel-Light'),
    url(/static/media/Isabel-Light.82d1f3ad.woff2) format('woff2'),
    url(/static/media/Isabel-Light.f69209fd.woff) format('woff'),
    url(/static/media/Isabel-Light.a1077e2f.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Isabel Light Italic';
  src: local('Isabel Light Italic'), local('Isabel-Light-Italic'),
    url(/static/media/Isabel-Light-Italic.44ff708b.woff2) format('woff2'),
    url(/static/media/Isabel-Light-Italic.4c5344f0.woff) format('woff'),
    url(/static/media/Isabel-Light-Italic.fa157524.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Isabel Regular';
  src: local('Isabel Regular'), local('Isabel-Regular'),
    url(/static/media/Isabel-Regular.c3780d18.woff2) format('woff2'),
    url(/static/media/Isabel-Regular.75ffda4e.woff) format('woff'),
    url(/static/media/Isabel-Regular.973a8050.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Linik Sans Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Linik Sans Italic'), url(/static/media/LinikSans-Italic.d34a3d00.woff) format('woff');
}

@font-face {
  font-family: 'Linik Sans Light';
  font-style: normal;
  font-weight: normal;
  src: local('Linik Sans Light'), url(/static/media/LinikSans-Light.e9599634.woff) format('woff');
}

@font-face {
  font-family: 'Linik Sans Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Linik Sans Light Italic'),
    url(/static/media/LinikSans-LightItalic.0ad004f6.woff) format('woff');
}

@font-face {
  font-family: 'Linik Sans';
  font-style: normal;
  font-weight: normal;
  src: local('Linik Sans Medium'), url(/static/media/LinikSans-Medium.e5d16240.woff) format('woff');
}

@font-face {
  font-family: 'Linik Sans Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Linik Sans Medium Italic'),
    url(/static/media/LinikSans-MediumItalic.068da889.woff) format('woff');
}

@font-face {
  font-family: 'Linik Sans Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Linik Sans Bold'), url(/static/media/LinikSans-Bold.70c54ddc.woff) format('woff');
}

@font-face {
  font-family: 'Linik Sans Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Linik Sans Bold Italic'),
    url(/static/media/LinikSans-BoldItalic.d11ef757.woff) format('woff');
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:root {
  --transition: all 0.2s ease;

  --light: rgb(255, 255, 255);
  --dark: rgba(41, 50, 65, 1);

  --primary: #37414e;
  --secondary: #67707c;
  --quaternary: rgba(170, 174, 187, 0.9);

  --purple: #7381ba;
  --purple_60: rgba(115, 129, 186, 0.6);

  --vivid-purple: rgba(60, 62, 232, 0.9);
  --vivid-purple__hover: rgb(60, 62, 232);

  --success: #5bd800;
  --success103: rgba(90, 216, 0, 0.3);
  --danger: rgba(238, 128, 77, 0.95);

  --link: rgba(60, 62, 232, 0.9);
  --link-change: rgb(60, 62, 232);

  --faint: #fbfbfb;
  --accent: rgba(209, 213, 231, 0.9);
  --active-accent: rgba(232, 232, 232, 0.98);

  --shadeLighter: rgb(241, 244, 247);
  --shadeLight: rgb(225, 225, 225);

  --shade1: rgb(250, 250, 250);
  --shade2: rgba(238, 243, 255, 0.6);
  --shade2_95: rgba(238, 243, 255, 0.95);
  --shade2_100: rgb(238, 243, 255);
  --hover: rgb(242, 242, 244);

  --tab-bg: rgb(209, 223, 255);
  --tab-bg__hover: rgb(176, 200, 255);

  --popover-bg: rgb(235, 240, 255);
  --popover--tab-bg__active: rgb(165, 188, 255);
  --popover--item-bg__hover: rgb(221, 230, 255);

  --select-icon-bg: rgb(235, 240, 255);
  --select-icon-bg__hover: rgb(221, 230, 255);
  --select-icon-border-color__active: rgb(165, 188, 255);

  --purple-bg: rgb(221, 230, 255);

  --outline: rgba(60, 62, 232, 0.4);
  --tag: rgba(241, 244, 255, 1);

  --heading-font-family: 'Isabel Bold', sans-serif;
  --heading-color: var(--primary);
  --heading2-font-family: 'Isabel Regular', sans-serif;
  --heading2-color: var(--secondary);
  --headingx-font-family: 'futura-pt', sans-serif;
  --heading-thin: 'Isabel Light', sans-serif;

  --body-font-family: 'futura-pt', sans-serif;
  --logo-font-family: 'Isabel Light', sans-serif;
  --caption-font-family: 'brandon-grotesque', serif;

  /* New Theme */

  /* 
  --purple: #ba9473;
  --purple_60: rgba(186, 156, 115, 0.6);

  --vivid-purple: rgba(181, 101, 26, 0.9);
  --vivid-purple__hover: rgb(232, 146, 60);

  rgb(179 191 214 / 70%)
  --link: rgb(120, 86, 45);
  --link-change: rgb(122, 79, 27); */
}

:root.dark {
  --brand: #f5ce9e;
  --brand-light: rgba(245, 206, 158, 0.1);

  --quaternary: #37414e;
  --secondary: #929ba7;
  --primary: rgba(170, 174, 187, 0.9);

  --link: #e5dbca;
  --link-change: rgb(245, 206, 158);

  /* --faint: #111317; */
  --faint: #151a24;
  --light: rgb(171, 169, 167);
  --primary: #fff;

  --shade1: rgb(19, 19, 19);
  --shade2: rgba(31, 33, 36, 0.6);
  --shade2_95: rgba(31, 33, 36, 0.95);
  --shade2_100: rgb(31, 33, 36);
  --shade3: rgb(29, 31, 34);
}

* {
  font-family: var(--body-font-family);
  letter-spacing: 0.05rem;
  box-sizing: border-box;
}

html,
body,
#root {
  font-size: 10px;
  width: 100%;
  height: 100%;
  margin: 0;
  background: var(--faint);
}

body.no-scroll {
  overflow: hidden;
}

a,
a:hover {
  text-decoration: none;
}

a:link,
a:active,
a:visited {
  color: var(--primary);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--heading-font-family);
  font-size: 4rem;
  font-weight: 500;
  color: var(--primary);
  margin: 0.4rem 0;
}

h1 {
  font-size: 5.2rem;
  color: var(--heading-color);
}

h2 {
  font-family: var(--heading2-font-family);
  font-size: 3.5rem;
  margin: 0.3rem 0;
}

h3 {
  font-family: var(--headingx-font-family);
  font-size: 3rem;
  margin: 0.2rem 0;
}

h4 {
  font-family: var(--headingx-font-family);
  font-size: 2.5rem;
  margin: 0.1rem 0;
}

h5 {
  font-family: var(--headingx-font-family);
  font-size: 1.8rem;
  margin: 0.1rem 0;
}

p {
  font-size: 1.8rem;
  color: var(--primary);
}

html,
body {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  width: 100%;
}

button,
a {
  position: relative;
  transition: var(--transition);
  border: 0;
  -webkit-appearance: none;
          appearance: none;
  background: none;
  cursor: pointer;
}

a:focus,
a:active,
button:focus,
button:active {
  outline: 0;
}

a:focus::after,
a:active::after,
button:not(.popover-nav__close):focus::after,
button:not(.popover-nav__close):active::after {
  content: '';
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  border: 2px dashed var(--outline);
  border-radius: 1.2rem;
}

textarea::-webkit-input-placeholder, input::-webkit-input-placeholder {
  color: var(--purple_60);
}

textarea::placeholder,
input::placeholder {
  color: var(--purple_60);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-webkit-autofill {
  -webkit-text-fill-color: var(--purple) !important;
  box-shadow: 0 0 0 30px var(--purple-bg) inset !important;
}

.hide {
  display: none !important;
  visibility: hidden !important;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.bold {
  font-weight: bold;
}

.ja-content {
  margin-top: 7.5rem;
}

@media (max-width: 768px) {
  html,
  body,
  #root {
    font-size: 8px;
  }
}



