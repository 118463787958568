.contact-slideout__wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  transition: all 0.3s ease-in-out;
}

.contact-slideout__wrapper.contact-slideout__wrapper--open::before {
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 98;
  background: var(--shade2);
  transition: all 0.5s linear;
}

.contact-slideout__wrapper.contact-slideout__wrapper--closed::before {
  content: '';
  position: fixed;
  background: var(--shade2);
  opacity: 0;
  transition: all 0.5s linear;
}

.contact-slideout__wrapper.contact-slideout__wrapper--open {
  opacity: 1;
  right: 0;
  transition: all 0.5s ease-in-out;
}

.contact-slideout__wrapper.contact-slideout__wrapper--close {
  right: -200vw;
  transition: all 1s ease-in-out;
}

.contact-slideout__container {
  position: relative;
  height: 100%;
  width: 75%;
  max-width: 70rem;
  padding: 2rem 4rem;
  margin-left: auto;
  background: var(--faint);
  overflow: scroll;
  z-index: 99;
  /* transition: all 0.5s ease-in-out; */
}

.contact-slideout__wrapper.contact-slideout__wrapper--open
  .contact-slideout__container {
  right: 0;
  transition: all 0.5s ease-in-out;
}

.contact-slideout__wrapper.contact-slideout__wrapper--close
  .contact-slideout__container {
  right: -200vw;
  transition: all 1s ease-in-out;
}

.contact-slideout__header {
  display: flex;
  height: 50px;
  border-bottom: 1px solid var(--purple-bg);
  margin-bottom: 2rem;
  justify-content: space-between;
  align-items: center;
}

.contact-slideout__close > .inline-svg__parent {
  display: block;
}

.contact-slideout__close-icon,
.contact-slideout__close-icon svg {
  display: block;
  width: 4rem;
  height: 4rem;
  color: var(--secondary);
  fill: var(--secondary);
  transition: all 0.2s ease-in-out;
}

.contact-slideout__close:hover svg {
  fill: var(--primary);
}

.contact-slideout__header button.contact-slideout__close:active::after,
.contact-slideout__header button.contact-slideout__close:focus::after {
  border: 0;
}

.contact-slideout__title {
  position: relative;
  color: var(--purple);
  font-size: 2.4rem;
  margin: 0;
}

@media (max-width: 825px) {
  .contact-slideout__container {
    padding: 2rem;
  }
}

@media (max-width: 625px) {
  .contact-slideout__container {
    width: 100%;
  }
}
