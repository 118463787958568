* {
  font-family: var(--body-font-family);
  letter-spacing: 0.05rem;
  box-sizing: border-box;
}

html,
body,
#root {
  font-size: 10px;
  width: 100%;
  height: 100%;
  margin: 0;
  background: var(--faint);
}

body.no-scroll {
  overflow: hidden;
}

a,
a:hover {
  text-decoration: none;
}

a:link,
a:active,
a:visited {
  color: var(--primary);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--heading-font-family);
  font-size: 4rem;
  font-weight: 500;
  color: var(--primary);
  margin: 0.4rem 0;
}

h1 {
  font-size: 5.2rem;
  color: var(--heading-color);
}

h2 {
  font-family: var(--heading2-font-family);
  font-size: 3.5rem;
  margin: 0.3rem 0;
}

h3 {
  font-family: var(--headingx-font-family);
  font-size: 3rem;
  margin: 0.2rem 0;
}

h4 {
  font-family: var(--headingx-font-family);
  font-size: 2.5rem;
  margin: 0.1rem 0;
}

h5 {
  font-family: var(--headingx-font-family);
  font-size: 1.8rem;
  margin: 0.1rem 0;
}

p {
  font-size: 1.8rem;
  color: var(--primary);
}

html,
body {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  width: 100%;
}

button,
a {
  position: relative;
  transition: var(--transition);
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
}

a:focus,
a:active,
button:focus,
button:active {
  outline: 0;
}

a:focus::after,
a:active::after,
button:not(.popover-nav__close):focus::after,
button:not(.popover-nav__close):active::after {
  content: '';
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  border: 2px dashed var(--outline);
  border-radius: 1.2rem;
}

textarea::placeholder,
input::placeholder {
  color: var(--purple_60);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-webkit-autofill {
  -webkit-text-fill-color: var(--purple) !important;
  -webkit-box-shadow: 0 0 0 30px var(--purple-bg) inset !important;
  box-shadow: 0 0 0 30px var(--purple-bg) inset !important;
}

.hide {
  display: none !important;
  visibility: hidden !important;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.bold {
  font-weight: bold;
}

.ja-content {
  margin-top: 7.5rem;
}

@media (max-width: 768px) {
  html,
  body,
  #root {
    font-size: 8px;
  }
}
