.purpose-modal__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background: var(--shade2);
}

.purpose-modal__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 90%;
  max-width: 650px;
  height: 90%;
  max-height: 600px;
  border: 2px solid var(--accent);
  border-radius: 1.2rem;
  background-color: var(--light);
  overflow: auto;
  outline: 0;
}

.purpose-modal {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-basis: 100%;
}

.purpose-modal__header {
  position: relative;
  top: 0;
  height: 75px;
  min-height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--shade2_100);
}

.purpose-modal__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  margin-right: 1rem;
}

.purpose-modal__title {
  font-size: 2.5rem;
}

.purpose-modal__icon img {
  height: 5rem;
}

.purpose-modal__body {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 3rem 2rem;
  overflow-y: auto;
}

.purpose-modal__language {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.purpose-modal__language--first-of {
  border-right: 1px solid var(--accent);
}

.purpose-modal__language-title {
  text-align: center;
  font-size: 2.8rem;
}

.purpose-modal ul {
  list-style: none;
  list-style-image: url('@ja/images/bullet-2x.svg');
  padding-left: 2rem;
  max-width: 400px;
  margin: 0 auto;
}

.purpose-modal__language-description-list li {
  margin-top: 2rem;
  font-size: 2.4rem;
  line-height: 3rem;
  color: var(--secondary);
}

.purpose-modal__report {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-left: 1.5rem;
}

.purpose-modal__report-content {
  width: 100%;
  flex-basis: 0;
  flex-grow: 8;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.purpose-modal__report-content h3 {
  margin-bottom: 1.5rem;
}

.purpose-modal__report-content h3 {
  font-size: 2.2rem;
}

.purpose-modal__report-content li {
  font-size: 1.8rem;
  line-height: 3rem;
  color: var(--secondary);
}

.purpose-modal .purpose-modal__tel-call {
  color: var(--link);
}

.purpose-modal__tel-call:hover {
  color: var(--link-change);
  text-decoration: underline;
}

.purpose-modal__footer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  height: 60px;
  min-height: 60px;
  background: var(--shade2_100);
}

.purpose-modal__cta--info,
.purpose-modal__cta {
  display: inline-block;
  width: 100%;
  max-width: 330px;
  height: 100%;
  max-height: 40px;
  margin: 10px;
  border: 2px solid var(--vivid-purple);
  border-radius: 1.2rem;
  font-size: 1.6rem;
  color: var(--light);
  background: var(--vivid-purple);
  letter-spacing: 0.1rem;
}

.purpose-modal__cta--info {
  max-width: 275px;
  color: var(--link);
  background: transparent;
  border-color: var(--link);
  opacity: 0.75;
}

.purpose-modal__cta:hover {
  color: var(--light);
  background: var(--link-change);
  transform: translateY(-1px);
}

.purpose-modal__cta--info:hover {
  color: var(--link-change);
  background: transparent;
  border-color: var(--link-change);
  transform: translateY(-1px);
  opacity: 1;
}

.purpose-modal__cta:active,
.purpose-modal__cta--info:active {
  transform: translateY(1px);
}

@media (max-width: 900px) {
  .purpose-modal__body {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .purpose-modal__language,
  .purpose-modal__report {
    display: block;
    flex-basis: 100%;
    padding-bottom: 2rem;
    border: 0;
    height: unset;
  }

  .purpose-modal__language--first-of {
    border-bottom: 1px solid var(--accent);
  }

  .purpose-modal__report {
    padding-top: 3rem;
    border: 0;
  }
}

@media (max-width: 768px) {
}

@media (max-width: 620px) {
  .purpose-modal__title {
    max-width: 207px;
  }

  .purpose-modal__cta {
    max-width: unset;
    align-self: center;
  }

  .purpose-modal__cta--info {
    display: none;
  }

  .purpose-modal__header {
    justify-content: flex-start;
  }

  .purpose-modal__content {
    width: 100%;
    height: 100%;
    max-height: unset;
    border-radius: 0;
    border-width: 1px;
  }
}

@media (max-width: 450px) {
  .purpose-modal__content {
    min-height: 50%;
  }
}
