.free-consultation-form__form {
  display: flex;
  flex-direction: column;
}

.free-consultation-form__form-row,
.free-consultation-form__form-row--button {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.free-consultation-form__form-row--button {
  justify-content: flex-end;
  align-items: flex-end;
  flex-flow: column nowrap;
}

.free-consultation-form__form-row.free-consultation-form__form-row--button
  .ja-button__wrapper {
  flex: 65%;
  width: 65%;
  min-width: 65%;
  max-width: 65%;
  transition: all 0.2s ease;
}

.free-consultation-form__form-row.free-consultation-form__form-row--button
  .ja-button__button {
  max-width: unset;
  width: unset;
  min-width: 100%;
  border: 2px solid transparent;
}

.free-consultation-form__form-row--button-success.free-consultation-form__form-row--button
  button.ja-button__button {
  border-color: var(--success);
  background: var(--success103);
  color: var(--primary);
  text-align: left;
}

.free-consultation-form__form-row--button-failed.free-consultation-form__form-row--button
  button.ja-button__button {
  border-radius: 0;
  color: var(--light);
  background: var(--danger);
}

.free-consultation-form__retry-container {
  flex: 65%;
  width: 65%;
  min-width: 65%;
  max-width: 65%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.free-consultation-form__retry-button {
  padding: 0.8rem 2rem;
  font-size: 1.4rem;
  color: var(--link);
  text-align: center;
  border: 1px solid var(--link);
  border-radius: 1.2rem;
  cursor: pointer;
}

.free-consultation-form__retry-button:hover {
  background: var(--hover);
  border-color: var(--link-change);
  color: var(--link-change);
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.free-consultation-form__label {
  display: block;
  flex: 34%;
  line-height: 26px;
  padding-top: 8px;
  margin-left: 5px;
  font-size: 1.6rem;
}

.free-consultation-form__label .note {
  line-height: 17px;
  width: 75%;
}

.free-consultation-form__label.free-consultation-form__label--has-focus {
  color: var(--vivid-purple);
}

.free-consultation-form__input {
  position: relative;
  display: block;
  flex: 65%;
  width: auto;
  line-height: 26px;
  padding: 5px 20px 8px 13px;
  border: 1px solid transparent;
  border-radius: 12px;
  background-color: var(--purple-bg);
  color: var(--vivid-purple);
  font-weight: 400;
  font-size: 1.8rem;
  outline: none;
  transition: background-color 0.1s ease-in, color 0.1s ease-in;
}

.free-consultation-form__input.free-consultation-form__input--select {
  padding: 0;
  width: 100%;
}

.free-consultation-form__input:focus {
  background-color: var(--faint);
  border: 1px solid var(--purple-bg);
}

.free-consultation-form__input--error,
.free-consultation-form__input--error:focus {
  color: var(--light);
  background: var(--danger);
  border-color: var(--danger) !important;
}

.free-consultation-form__input--error::placeholder {
  color: var(--light);
}

.free-consultation-form__input--error + #feedback::after {
  content: '!';
  position: absolute;
  width: 29px;
  height: 29px;
  line-height: 29px;
  color: var(--danger);
  font-size: 13px;
  right: 6px;
  top: 6px;
  text-align: center;
  font-weight: bold;
  border-radius: 1.2rem;
  background: var(--light);
  z-index: 999999;
}

.free-consultation-form__form-row--button .ja-button__button:focus::after {
  border: 0;
}

#feedback {
  display: flex;
  flex-basis: 100%;
  justify-content: flex-start;
  margin-left: 37%;
  margin-top: 5px;
  color: var(--danger);
  letter-spacing: 1px;
  font-size: 12px;
}

@media (max-width: 825px) {
  .free-consultation-form__form-row,
  .free-consultation-form__form-row--button {
    width: 100%;
    flex-direction: column;
  }

  .free-consultation-form__input {
    width: 100%;
  }

  .free-consultation-form__label {
    width: 100%;
    flex: 100%;
    font-size: 16px;
    padding-bottom: 5px;
  }

  .free-consultation-form__label .note {
    width: 100%;
  }

  .free-consultation-form__input {
    flex: 100%;
  }

  .free-consultation-form__retry-container,
  .free-consultation-form__form-row.free-consultation-form__form-row--button
    .ja-button__wrapper {
    flex: 100%;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  .free-consultation-form__input--error + #feedback::after {
    top: 45px;
  }

  .free-consultation-form__input--error + #feedback {
    margin-left: unset;
    margin-right: 1.2rem;
    align-self: flex-end;
  }
}
