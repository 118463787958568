.spinner__container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--faint);
}

.spinner__loader,
.spinner__loader:after {
  border-radius: 50%;
  width: 12rem;
  height: 12rem;
}

.spinner__loader {
  position: fixed;
  display: block;
  margin: 0 auto;
  font-size: 1rem;
  text-indent: -9999rem;
  border-top: 1.2rem solid var(--shadeLighter);
  border-right: 1.2rem solid var(--shadeLighter);
  border-bottom: 1.2rem solid var(--shadeLighter);
  border-left: 1.2rem solid var(--vivid-purple);
  top: 50%;
  left: 50%;
  margin-top: -6rem;
  margin-left: -6rem;
  transform: translateZ(0);
  animation: spin 1.1s infinite linear;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner__container--inline {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background: transparent;
}

.spinner__container--inline .spinner__loader {
  position: unset;
  justify-self: flex-start;
  width: 20px;
  height: 20px;
  margin: 0;
  margin-top: 2.5px;
  margin-right: 20px;
  border: 3px solid var(--shade2);
  border-top: 3px solid var(--faint);
  border-radius: 50%;
  transition: all 0.25s linear;
  animation: spin 1s linear infinite;
}
.spinner__container--inline .spinner__loader::after {
  display: none;
}
