@font-face {
  font-family: 'Linik Sans Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Linik Sans Italic'), url('LinikSans-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Linik Sans Light';
  font-style: normal;
  font-weight: normal;
  src: local('Linik Sans Light'), url('LinikSans-Light.woff') format('woff');
}

@font-face {
  font-family: 'Linik Sans Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Linik Sans Light Italic'),
    url('LinikSans-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'Linik Sans';
  font-style: normal;
  font-weight: normal;
  src: local('Linik Sans Medium'), url('LinikSans-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Linik Sans Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Linik Sans Medium Italic'),
    url('LinikSans-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'Linik Sans Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Linik Sans Bold'), url('LinikSans-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Linik Sans Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Linik Sans Bold Italic'),
    url('LinikSans-BoldItalic.woff') format('woff');
}
