.modal__close-modal,
.modal__close-modal:focus::after {
  border: 0;
  outline: 0;
}

.modal__close-modal,
.modal__close-modal-icon svg {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 4rem;
  height: 4rem;
  fill: var(--secondary);
  outline: 0;
  z-index: 99;
}

.ReactModal__Content button.modal__close-modal:active::after,
.ReactModal__Content button.modal__close-modal:focus::after {
  border: 0;
}

.modal__close-modal:hover svg {
  fill: var(--primary);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}
