:root {
  --transition: all 0.2s ease;

  --light: rgb(255, 255, 255);
  --dark: rgba(41, 50, 65, 1);

  --primary: #37414e;
  --secondary: #67707c;
  --quaternary: rgba(170, 174, 187, 0.9);

  --purple: #7381ba;
  --purple_60: rgba(115, 129, 186, 0.6);

  --vivid-purple: rgba(60, 62, 232, 0.9);
  --vivid-purple__hover: rgb(60, 62, 232);

  --success: #5bd800;
  --success103: rgba(90, 216, 0, 0.3);
  --danger: rgba(238, 128, 77, 0.95);

  --link: rgba(60, 62, 232, 0.9);
  --link-change: rgb(60, 62, 232);

  --faint: #fbfbfb;
  --accent: rgba(209, 213, 231, 0.9);
  --active-accent: rgba(232, 232, 232, 0.98);

  --shadeLighter: rgb(241, 244, 247);
  --shadeLight: rgb(225, 225, 225);

  --shade1: rgb(250, 250, 250);
  --shade2: rgba(238, 243, 255, 0.6);
  --shade2_95: rgba(238, 243, 255, 0.95);
  --shade2_100: rgb(238, 243, 255);
  --hover: rgb(242, 242, 244);

  --tab-bg: rgb(209, 223, 255);
  --tab-bg__hover: rgb(176, 200, 255);

  --popover-bg: rgb(235, 240, 255);
  --popover--tab-bg__active: rgb(165, 188, 255);
  --popover--item-bg__hover: rgb(221, 230, 255);

  --select-icon-bg: rgb(235, 240, 255);
  --select-icon-bg__hover: rgb(221, 230, 255);
  --select-icon-border-color__active: rgb(165, 188, 255);

  --purple-bg: rgb(221, 230, 255);

  --outline: rgba(60, 62, 232, 0.4);
  --tag: rgba(241, 244, 255, 1);

  --heading-font-family: 'Isabel Bold', sans-serif;
  --heading-color: var(--primary);
  --heading2-font-family: 'Isabel Regular', sans-serif;
  --heading2-color: var(--secondary);
  --headingx-font-family: 'futura-pt', sans-serif;
  --heading-thin: 'Isabel Light', sans-serif;

  --body-font-family: 'futura-pt', sans-serif;
  --logo-font-family: 'Isabel Light', sans-serif;
  --caption-font-family: 'brandon-grotesque', serif;

  /* New Theme */

  /* 
  --purple: #ba9473;
  --purple_60: rgba(186, 156, 115, 0.6);

  --vivid-purple: rgba(181, 101, 26, 0.9);
  --vivid-purple__hover: rgb(232, 146, 60);

  rgb(179 191 214 / 70%)
  --link: rgb(120, 86, 45);
  --link-change: rgb(122, 79, 27); */
}

:root.dark {
  --brand: #f5ce9e;
  --brand-light: rgba(245, 206, 158, 0.1);

  --quaternary: #37414e;
  --secondary: #929ba7;
  --primary: rgba(170, 174, 187, 0.9);

  --link: #e5dbca;
  --link-change: rgb(245, 206, 158);

  /* --faint: #111317; */
  --faint: #151a24;
  --light: rgb(171, 169, 167);
  --primary: #fff;

  --shade1: rgb(19, 19, 19);
  --shade2: rgba(31, 33, 36, 0.6);
  --shade2_95: rgba(31, 33, 36, 0.95);
  --shade2_100: rgb(31, 33, 36);
  --shade3: rgb(29, 31, 34);
}
